import { mapActions } from 'vuex'
import { myMixin } from '../../../mixins'

export default{
  mixins: [myMixin],
  data: () => ({
    collections: [],
    params: {},
    message: '',
    error: false,
    errors: {},
    loading: false,
    meta: {},
    itemDelete: {},
    model: {},
    show: false,
    showForm: false,
    text: 'Carregando dados...',
    loadingProgress: false,
    textDelete: '',
    deleting: false,
    headers: [
        { text: 'Professor', value: 'teacher', sortable: false},
        { text: 'Empresa', value: 'company', sortable: false },
        { text: 'Cargo', value: 'position.name', sortable: false },
        { text: 'Vínculo', value: 'relationship', sortable: false, type: 'initials' },
        { text: 'Valor Hora', value: 'hour_value', type: 'money' },
        { text: 'Nível de Curso', value: 'courseLevels', type: 'array', sortable: false },
        { text: 'Modalidade', value: 'courseModalities', type: 'array', sortable: false },
        { text: 'Principal', value: 'is_main', type: 'boolean', align: 'center'},
        { text: 'Status', value: 'active', type: 'boolean'},
        { text: 'Ações', value: 'actions', sortable: false}
    ]
    }),
    
    watch: {
      params(params_new, params_old){
        if(params_new != params_old) {
            this.getData()
        }
      },
       //Função para tratar quando um item for exibido para deletar
      itemDelete(item_new, item_old){
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;
              this.textDelete = "Deseja excluir o item ?";
          } else {
              this.show = false
              this.textDelete = '';
          }
      }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
      show(show_new, show_old) {
          
          if(show_new != show_old && !show_new) {
              this.itemDelete = {};
          }
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.model.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.model = {};
          }
      },
    },
    methods: {
      ...mapActions('teacherContract', ['ActionFindTeacherContracts', 'ActionDeleteTeacherContract']),
        
      getData(){
      
        this.error = false;
        this.message = '';
        this.loadingProgress = true;
        this.showForm = false;

        this.params.with = 'teacher,campuses,courses,position,courseLevels,relationship,courseModalities,company,branch';

        this.ActionFindTeacherContracts(this.params)
            .then((res) => {
                this.collections = res.data;
                this.meta = res.meta;
            })
            .finally(() => {
                this.loading = false;
                this.loadingProgress = false;
            })
    },

    getCampuses(item){

        if(item.campuses && item.campuses.length  > 0){
            
            let total = item.campuses.length;
            let limit = 2;
            
            if(total < limit){
                return item.campuses.map(o => o['name']).join(', ');
            }else{
                let items = [];

                for (let index = 0; index < limit; index++) {
                    items.push(item.campuses[index]);
                    
                }

                return items.map(o => o['name']).join(', ') + ' (+' + (total - limit) + ')';
            }

            
        }else{
            return "";
        }

    },

    deleteItem()
        {
            this.deleting = true;
            this.message = '';

            this.ActionDeleteTeacherContract({ id: this.itemDelete.id })
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                  this.message = error.message;
                  this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                });
        }

  },

}
